<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <KeepAlive>
      <router-view />
    </KeepAlive>
  </div>
</template>
<script>
  export default {
    mounted() {
    }
  }
</script>  
<style lang="scss">
  /* 全部默认样式清空 */
  * {
    margin: 0;
    padding: 0;
    // box-sizing: border-box;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex{
    display: flex;
  }
  .flex-space-around{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .flex-space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-space-evenly{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
</style>
