// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
const messages = {
	en: require('./lang/en'),
	zh: require('./lang/zh'),
	es: require('./lang/es'),
	ar: require('./lang/ar'),
	pt: require('./lang/pt'),
	// ru: require('./lang/ru'),
	de: require('./lang/de'),
	fr: require('./lang/fr'),
	it: require('./lang/it'),
	// hr: require('./lang/hr'),
	// el: require('./lang/el'),
	tr: require('./lang/tr'),
	nl: require('./lang/nl'),
	pl: require('./lang/pl'),
	cs: require('./lang/cs'),
	// hu: require('./lang/hu'),
	// is: require('./lang/is'),
	// da: require('./lang/da'),
	// no: require('./lang/no'),
	sv: require('./lang/sv'),
	// fi: require('./lang/fi'),
	// ro: require('./lang/ro'),
	// iw: require('./lang/iw'),
	ja: require('./lang/ja'),
	kr: require('./lang/kr'),
	// th: require('./lang/th'),
	// mn: require('./lang/mn'),
	// np: require('./lang/np'),
	// my: require('./lang/my'),
}
Vue.use(VueI18n);
var language = localStorage.getItem('language')
const i18n = new VueI18n({
    locale: language ? language : 'en',// 获取已设置的语言 localStorage.getItem('language') || 'en',
    messages,
});

export default i18n;
